@font-face {
  font-family: 'Calibri';
  src: url('./assets/font/calibri-regular.ttf');
}
@font-face {
  font-family: 'Calibri Bold';
  src: url('./assets/font/calibri-bold.ttf');
}

@font-face {
  font-family: 'Shopee Display';
  src: url('./assets//font/shopee-display.ttf');
}

:root {
  --main-color: #ff6c00;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-family: 'Calibri', sans-serif;
}

a {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}
/* OTP input */
.otp-input {
  width: 100%;
  justify-content: space-between !important;
}

.inputStyle {
  width: 56.667px !important;
  height: 46px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
}

/* Swiper */
.swiper-button-next,
.swiper-button-prev {
  background-color: white;
  background-color: rgba(255, 255, 255, 0.5);
  right: 10px;
  padding: 30px 20px;
  color: rgba(255, 255, 255, 0.5) !important;
  fill: rgba(255, 255, 255, 0.5) !important;
  strokewidth: 5px;
  stroke: rgba(255, 255, 255, 0.5);
}

/* Antd */
.ant-tabs-ink-bar {
  height: 4px;
  background: var(--main-color) !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  /* background-color: #56b947; */
}

.ant-tabs-nav-list {
  width: 100% !important;
}

.ant-tabs-tab {
  width: 50%;
  display: flex;
  justify-content: center;
}

.ant-form-item label,
.ant-table-thead {
  font-family: 'Calibri', sans-serif;
  color: #05422c;
}

.ant-btn-primary {
  box-shadow: none;
}

.ant-form-item label {
  font-weight: 700;
}

.ant-picker {
  width: 50%;
}

.ant-table-thead {
  font-weight: 700;
  font-size: 20px;
}

.ant-dropdown {
  min-width: 300px !important;
}

.ant-dropdown-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.ant-collapse .ant-collapse-content {
  background-color: #f2f2f2 !important;
}

.ant-collapse-header {
  align-items: center !important;
}

.ant-tabs-nav-operations {
  display: none !important;
}
/* Pyament History */
.payment-history-form .ant-form-item label,
#change-password-form label {
  font-size: 18px;
  height: 100%;
}
.payment-history-form #start_date,
.payment-history-form #end_date,
.payment-history-form .ant-select-selector {
  font-size: 18px;
  font-family: 'Calibri', sans-serif;
}
.payment-history-form .ant-select-selector {
  height: 38px !important;
}
.payment-history-form .ant-select-selector .ant-select-selection-item {
  line-height: 38px !important;
}

/* Chnage password form */
#change-password-form input {
  font-size: 16px;
}

/* Forgot password form */
#forgot-password-form input,
#forgot-password-verify input {
  font-size: 18px;
}
#forgot-password-verify label {
  font-size: 18px;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .ant-picker {
    width: 100%;
  }
  .ant-picker-panels {
    flex-wrap: wrap !important;
    width: 324px !important;
  }

  .payment-history-form .ant-form-item label {
    font-size: 16px;
  }
  .payment-history-form #start_date,
  .payment-history-form #end_date,
  .payment-history-form .ant-select-selector {
    font-size: 16px;
  }
}

@media screen and (max-width: 575px) {
  .payment-history-form .ant-form-item-label {
    flex: 1 !important;
  }
  .payment-history-form .ant-form-item-control {
    flex: 2 !important;
  }
  .payment-history-form .ant-table-cell {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  .payment-history-form .ant-table-thead {
    font-size: 16px;
  }

  .ant-table-thead {
    font-size: 15px;
  }
  .payment-history-table .ant-table-cell {
    padding: 10px !important;
  }
  .inputStyle {
    width: 42px !important;
    height: 42px;
  }
}
